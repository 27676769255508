<template>
  <div>
    <Pane :title="detail.title" />

    <a-card class="container">
      <div class="right" style="padding-bottom: 8px">
        <a-space>
          <a-button @click="getData">刷新</a-button>
          <a-button type="primary" @click="download">导出表格</a-button>
        </a-space>
      </div>
      <a-table
        bordered
        :data-source="scoredPersonalList"
        :pagination="false"
        :loading="loading"
      >
        <a-table-column title="部门" data-index="deptName"></a-table-column>
        <a-table-column title="姓名" data-index="userName"></a-table-column>
        <a-table-column title="备注" data-index="explain"></a-table-column>
        <a-table-column title="总分" align="center">
          <template slot-scope="text">
            <span style="color: #1890ff">
              {{ text.allScore }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="openDetail(text)">详情</a>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <a-modal
      :title="text.userName + '分数详情'"
      :visible="visible"
      @cancel="visible = false"
      :footer="null"
      width="640px"
    >
      <a-table
        bordered
        :data-source="Array.isArray(text.personalList) ? text.personalList : []"
        :pagination="false"
      >
        <a-table-column title="领导" data-index="userName"></a-table-column>
        <a-table-column title="分数" align="center">
          <template slot-scope="text">
            <span style="color: #1890ff">
              {{ text.score }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="openJudgeDetail(text)">详情</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>

    <a-modal
      :title="judge.userName + '打分详情'"
      :visible="judgeVisible"
      @cancel="judgeVisible = false"
      :footer="null"
      width="640px"
    >
      <a-table
        bordered
        :data-source="Array.isArray(judge.optionList) ? judge.optionList : []"
        :pagination="false"
      >
        <a-table-column title="指标名" data-index="title"></a-table-column>
        <a-table-column
          title="指标说明"
          data-index="annotation"
        ></a-table-column>
        <a-table-column title="分值" align="center" width="100px">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.remark }}</span>
          </template>
        </a-table-column>
        <a-table-column title="权重" align="center" width="100px">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.weight }}</span>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { fetchResult } from "./api";
import { downloadByHtml } from "@/utils/xlsx";
export default {
  data() {
    return {
      loading: false,
      detail: {},
      list: [],

      text: {},
      visible: false,
      judge: {},
      judgeVisible: false,
    };
  },

  computed: {
    scoredPersonalList() {
      return Array.isArray(this.detail.scoredPersonalList)
        ? this.detail.scoredPersonalList
            .map((item) => {
              // 所有评委打的分
              let allScore = 0;

              if (Array.isArray(item.personalList)) {
                item.personalList.forEach((element) => {
                  let score = 0; // 单个评委打的分
                  if (Array.isArray(element.optionList)) {
                    element.optionList.forEach((option) => {
                      score += parseInt(option.remark) * option.weight;
                    });
                  }
                  console.log("juedge score", score);
                  element.score = parseFloat(score.toFixed(2));

                  allScore += element.score;
                });
              }

              return {
                ...item,
                allScore,
              };
            })
            .sort((x, y) => {
              if (x.allScore < y.allScore) {
                return 1;
              } else if (x.allScore > y.allScore) {
                return -1;
              } else {
                return 0;
              }
            })
        : [];
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      const { query } = this.$route;
      const { id } = query || {};

      this.loading = true;
      fetchResult({
        id,
      })
        .then((res) => {
          this.detail = res ? Object.freeze(res) : {};
        })
        .finally(() => {
          this.loading = false;
        });
    },

    openDetail(text) {
      this.text = text;
      this.visible = true;
    },
    openJudgeDetail(text) {
      this.judge = {
        ...text,
        optionList: Array.isArray(text.optionList)
          ? text.optionList.map((item) => {
              let title, annotation;
              if (Array.isArray(this.detail.subjectList)) {
                const obj = this.detail.subjectList.find(
                  (element) => element.id === item.subjectId
                );
                if (obj) {
                  title = obj.title;
                  annotation = obj.annotation;
                }
              }
              return {
                ...item,
                title,
                annotation,
              };
            })
          : [],
      };
      this.judgeVisible = true;
    },

    download() {
      const table = document.getElementsByTagName("table")[0];
      if (table) {
        downloadByHtml(table, this.detail.title + "评分结果");
      }
    },
  },
};
</script>